@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');


@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap');
*
{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
header
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 40px 100px;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header .logo
{
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
}
/* .toggle
{
  position: relative;
  width: 60px;
  height: 60px;
  background: url(https://i.ibb.co/HrfVRcx/menu.png);
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: center;
  cursor: pointer;
}
.toggle.active
{
  background: url(https://i.ibb.co/rt3HybH/close.png);

  background-size: 25px;
  background-position: center;
  cursor: pointer;
} */
.showcase
{
  position: absolute;
  right: 0;
  width: 100%;
  min-height: 100vh;
  padding: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  transition: 0.5s;
  z-index: 2;
}
.showcase.active
{
  right: 300px;
}

.showcase video
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 100%;
}
.overlay
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  mix-blend-mode: overlay;
}
.text
{
  position: relative;
  z-index: 10;
}

.text h2
{
  font-family: 'Montserrat';
  font-size: 5em;
  font-weight: bold;
  font-weight: 800;
  color: #ffffff;
  line-height: 1em;
  margin-bottom: 30px;
  
 
}

.glass {
  position: relative;
  border-radius: 20px;
  border: 1.5px solid rgba(228, 228, 228, 0.54);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.784), rgba(44, 44, 44, 0.632));
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding: 25px;

  transition: 0.5s;

}



.glass::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  opacity: 0;
  
  transition: opacity 0.3s;
}

.glass:hover::before {
  opacity: 1;
  background: linear-gradient(0deg,rgba(112, 112, 112, 0.424), rgba(0, 0, 0, 0.344));
  transition: 0.5s;
  z-index: -1; /* Add this line to adjust the stacking order */

}

.glass:hover {
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
 
  border: 1.5px solid rgba(255, 255, 255, 0.741);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);

}



.text a
{
  display: inline-block;
  font-size: 1em;
  font-family: 'Montserrat';
  background: #ffffff;
  padding: 15px 20px;
  text-decoration: none;
  font-weight: 700;
  border-radius: 10px;
  color: #000000;
  border: 2px solid rgba(228, 228, 228, 0.54);
  
  transition: 0.3s;
  box-shadow: 0px 0px 20px rgb(90, 90, 90);
  
}

.text h3
{ 
  font-size: 4em;
  font-weight: 700;
  color: #ffffff;
  line-height: 1em;
  text-transform: uppercase;

}
.text p
{
  font-size: 1.1em;
  color: #ffffff;
  margin: 20px 0;
  font-weight: 400;
  max-width: 700px;
  margin-bottom: 30px;
}


.text a:hover
{
  color: #ffffff;
  letter-spacing: 2px;
  background: rgb(20, 20, 20);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1.5px solid rgba(255, 255, 255, 0.741);
  box-shadow: 0 0px 25px 0 rgba(214, 214, 214, 0.486);
     
}

.social {
  position: fixed;
  z-index: 10;
  bottom: 40px;
  right: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.social li {
  list-style: none;
  margin: 0 20px;
}

.social li a {
  display: inline-block;
  width: 60px;
  height: 60px;
  transition: 0.5s;
}

.social li a img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Maintain original aspect ratio */
}

.social li a:hover {
  transform: translateY(-15px);
}


.menu
{
  position: absolute;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu ul
{
  position: relative;
}
.menu ul li
{
  list-style: none;
}
.menu ul li a
{
  text-decoration: none;
  font-size: 24px;
  color: #111;
}
.menu ul li a:hover
{
  color: #111111; 
}

@media (max-width: 991px)
{
  .showcase,
  .showcase header
  {
    padding: 40px;
  }
  .text h2
  {
    font-size: 3em;
  }
  .text h3
  {
    font-size: 2em;
  }
}