.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.525);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
 
  position: relative;
  border-radius: 20px;
  border: 1.5px solid rgba(228, 228, 228, 0.54);
  background: rgba(0, 0, 0, 0.606);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding: 25px;
  height: 200px;
  width: 400px;
  display: flex;

  box-shadow: 0 0px 80px 0 rgba(68, 68, 68, 0.908);

  flex-direction: column;
  justify-content: center;
  align-items: center;
  }

.modal-content h2 {
  margin-top: 0;
}



.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 20px;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-size: 25px;
  color: white;
}

.button-image {
  width: 30px; /* Adjust this value as needed */
  height: 30px; /* Adjust this value as needed */
  vertical-align: middle; /* Align the image vertically in the middle */
  margin-right: 8px; /* Add some margin between the image and text */
}

.openModalBtn
{

display: inline-block;
font-size: 1em;
font-family: 'Montserrat';
background: #161616;
padding: 10px 20px;
text-decoration: none;
align-items: center;
font-weight: 700;
border-radius: 10px;
color: #ffffff;
border: 1px solid rgb(255, 255, 255);

transition: 0.3s;
margin-top: 20px; /* Add margin to separate the button from the close button */
width: fit-content; /* Adjust the width based on the content */
white-space: nowrap; /* Prevent the button from wrapping onto a new line */


}

.openModalBtn:hover
{
color: #ffffff;
background: linear-gradient(135deg, rgba(0, 55, 61, 0.613), rgba(28, 0, 57, 0.618), rgba(100, 85, 0, 0.669), rgba(4, 63, 0, 0.685));
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.741);
box-shadow: 0 0px 20px 0 rgba(245, 243, 243, 0.405);
   
}

/* Loading dots styles */
@keyframes opacity {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

.loadingButton {
  pointer-events: none;
  box-shadow: 0 0px 20px 0 rgba(245, 243, 243, 0.405);
}

.loadingButton #loading {
  display: flex;
}

#loading {
display: inline-block;

text-decoration: none;
align-items: center;
font-weight: 700;
color: rgb(255, 255, 255);


}

#loading span:not(:last-child) {
  margin-right: 5px;
}

#loading span {
  animation-name: opacity;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

#loading span:nth-child(2) {
  animation-delay: 100ms;
  animation-delay: 100ms;
}

#loading span:nth-child(3) {
  animation-delay: 300ms;
  animation-delay: 300ms;
}